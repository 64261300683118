#root_adql_query {
  width:600px;
  height:150px;
}

.selectedServices {
  width:auto;
}

.metadata-tree {
  width: 450px;
  max-height: 80%;
  position: absolute;
  z-index: 1;
  top: 132px;
  right: 5%;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 18px 10px;
  border: 1px solid #ced4da;
}

.metadata-tree a {
  padding: 16px 18px 16px 16px;
  color: #2196F3;
  display: block;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
}

