.App {
  text-align: center;
}

.App-logo {
  height: 60vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: inherit;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #1315c4;
}

.form-check2 {
  width: 120px;
  height: 40px;
  background: #333;
  margin: 20px 60px;

  border-radius: 50px;
  position: relative;
}

.aladin {
  width: 100%;
  height:600px;
}

footer {
  margin-left:10px;
  margin-bottom:5px;
}
